<template>
  <div>
    <Movement_38menuVue/>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลอัตรากำลังรับย้าย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="3"
              ><v-btn
                block
                color="info"
                :href="'#/admin/print_report_manpower'"
                target="_blank"
              >
                <v-icon class="pa-1">
                  mdi-printer
                </v-icon>
                รายงานตำแหน่งว่างคงเหลือ
              </v-btn></v-col
            >
            <v-col cols="12" md="3" class="text-right">
              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="man_power_38Add()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="man_power_38Replace()"
              >
                <v-icon>mdi-account-search</v-icon>ตรวจสอบข้อมูลซ้ำ
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="primary"
                @click.native="man_power_38CSV()"
              >
                <v-icon>mdi-file-import</v-icon>นำเข้าข้อมูลอัตรารับย้าย
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="red"
                @click.native="man_power_38Clear()"
              >
                <v-icon>mdi-delete-sweep</v-icon>ล้างข้อมูลตำแหน่งว่าง
              </v-btn>

            <!--   <v-btn
                block
                right
                depressed
                color="red"
                @click="sweetAlertLoading()"
              >
                <v-icon>mdi-delete-sweep</v-icon>sweet
              </v-btn> -->
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="man_power_38s"
          :search="search"
        >
        <template v-slot:item.organization="{item}">
          <div>
            {{ item.user_firstname }} {{ item.user_lastname || "" }}
          </div>
        </template>
          <template v-slot:item.mp_status_booking="{ item }">
            <v-chip dark color="warning" v-if="item.mp_status_booking">
              {{ item.mp_status_booking }}
            </v-chip>
            <v-chip dark color="green" v-else>
              ว่าง
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="yellow" @click.stop="man_power_38Edit(item.id_m)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon color="red" @click.stop="man_power_38Delete(item.id_m)">
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addman_power_38dialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addman_power_38dialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลอัตรากำลังรับย้าย"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addman_power_38form" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="users"
                        item-text="agencys"
                        item-value="user_code"
                        v-model="addman_power_38.mp_college_code"
                        label="สถานศึกษา"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เลขที่ตำแหน่ง"
                        v-model="addman_power_38.mp_id_position"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-flex md6>
                        <v-autocomplete
                          v-model="addman_power_38.mp_position"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_name"
                          label="ตำแหน่ง"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="กรณี"
                        v-model="addman_power_38.mp_case_vacancy"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addman_power_38dialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addman_power_38Submit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteman_power_38dialog -->
      <v-layout>
        <v-dialog v-model="deleteman_power_38dialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ข้อมูลอัตรากำลังรับย้าย"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteman_power_38form" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editman_power_38.agencys +
                              " : " +
                              editman_power_38.mp_id_position
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteman_power_38dialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteman_power_38Submit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editman_power_38dialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editman_power_38dialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editman_power_38form" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="users"
                        item-text="agencys"
                        item-value="user_code"
                        v-model="editman_power_38.mp_college_code"
                        label="สถานศึกษา"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เลขที่ตำแหน่ง"
                        v-model="editman_power_38.mp_id_position"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-flex md6>
                        <v-autocomplete
                          v-model="editman_power_38.mp_position"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_name"
                          label="ตำแหน่ง"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="กรณี"
                        v-model="editman_power_38.mp_case_vacancy"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editman_power_38dialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editman_power_38Submit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model ClearMan_powerdialog -->
      <v-layout>
        <v-dialog v-model="ClearMan_powerdialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ข้อมูลอัตรากำลังรับย้าย"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="ClearMan_powerform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12> ยืนยันการล้างข้อมูล <br /> </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="ClearMan_powerdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="ClearMan_powerSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ยืนยันการล้างข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model man_power_38CSVdialog -->
      <v-layout>
        <v-dialog v-model="man_power_38CSVdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลอัตรากำลังรับย้าย"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="man_power_38CSVdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            block
                            large
                            color="info"
                            to="/admin/man_power_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV อัตรากำลัง"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="man_power_38CSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Movement_38menuVue from '../../components/admin/movement_38menu.vue';

export default {
    data() {
        return {
            loading: true,
            ApiKey: "HRvec2021",
            valid: true,
            file2: null,
            addman_power_38dialog: false,
            editman_power_38dialog: false,
            deleteman_power_38dialog: false,
            ClearMan_powerdialog: false,
            man_power_38CSVdialog: false,
            snackbar: {
                show: false,
                color: "",
                timeout: 5000,
                icon: "",
                text: ""
            },
            man_power_38s: [],
            addman_power_38: {},
            editman_power_38: {},
            users: [],
            userstatus: [],
            search: "",
            pagination: {},
            headers: [
                { text: "หน่วยงาน", align: "left", value: "organization" },
                { text: "จังหวัด", align: "left", value: "province_name" },
                { text: "อัตราว่าง", align: "left", value: "num_position" },
                { text: "รหัสตำแหน่ง", align: "left", value: "mp_id_position" },
                { text: "ตำแหน่ง", align: "left", value: "mp_position" },
                { text: "กรณี", align: "left", value: "mp_case_vacancy" },
                { text: "จอง", align: "left", value: "mp_status_booking" },
                { text: "สถานะ", align: "left", value: "id_card" },
                { text: "ชื่อ", align: "left", value: "frist_name" },
                { text: "นามสกุล", align: "left", value: "last_name" },
                {
                    text: "แก้ไข",
                    align: "center",
                    value: "actions",
                    icon: "mdi-file-document-edit"
                },
                {
                    text: "ลบ",
                    align: "center",
                    value: "action_s",
                    icon: "mdi-delete-forever"
                }
            ],
            rowsperpage: [
                25,
                50,
                100,
                {
                    text: "All",
                    value: -1
                }
            ],
            college: {},
            provinces: [],
            prefectures: [],
            collgegs: [],
            man_power_38status: [],
            regions: [],
            region_ena: true,
            data_syslog: {}
        };
    },
    async mounted() {
        await this.man_power_38QueryAll();     
        
        let result;
        result = await this.$http.post("user.php", {
            ApiKey: this.ApiKey,
            admin:'ok'
        });
        this.users = result.data;

        let user_status_result;
        user_status_result = await this.$http.post("crud_user_status.php?crud=read", {
            ApiKey: this.ApiKey
        });
        this.userstatus = user_status_result.data;


    },
    methods: {
        async csvManpowerSubmit() {
            let result = "";
            let uploaded = null;
            if (this.file2) {
                let formData = new FormData();
                let filename = this.time_stamp + ".manpower.csv";
                formData.append("file", this.file2);
                formData.append("filename", "../HRvecfiles/" + filename);
                formData.append("ApiKey", this.ApiKey);
                result = await this.$http.post("man_power_38.csv.php", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                uploaded = true;
            }
            else {
                uploaded = false;
            }
            if (result.data.status == true) {
                Swal.fire({
                    icon: "success",
                    title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.man_power_38QueryAll();
            }
            else {
                Swal.fire({
                    icon: "warning",
                    title: "ดำเนินการผิดพลาด",
                    showConfirmButton: false,
                    timer: 1500
                });
                this.man_power_38QueryAll();
            }
            this.man_power_38CSVdialog = false;
        },
        async man_power_38CSV() {
            this.man_power_38CSVdialog = true;
        },
        async man_power_38Clear() {
            this.ClearMan_powerdialog = true;
        },
        async sweetAlertLoading() {
            let timerInterval;
            Swal.fire({
                title: "Auto close alert!",
                html: "I will close in <b></b> milliseconds.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then(result => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    
                }
            });
        },
        async man_power_38Replace() {
            this.loading = true;
            let result = await this.$http
                .post("man_power_38.php", {
                ApiKey: this.ApiKey,
                man_power_38Check: "Ok"
            })
                .finally(() => (this.loading = false));
            this.man_power_38s = result.data;
        },
        async man_power_38QueryAll() {
            this.loading = true;
            let result = await this.$http
                .post("man_power_38.php", {
                ApiKey: this.ApiKey
            })
                .finally(() => (this.loading = false));
            this.man_power_38s = result.data;
        },
        async man_power_38Add() {
            this.addman_power_38 = {};
            this.addman_power_38dialog = true;
        },
        async addman_power_38Submit() {
            if (this.$refs.addman_power_38form.validate()) {
                this.addman_power_38.ApiKey = this.ApiKey;            
                let result = await this.$http.post("man_power_38.insert.php", this.addman_power_38);
               
                if (result.data.status == true) {
                    this.man_power_38 = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "insert";
                    this.data_syslog.page_log = "man_power_38";
                    this.data_syslog.table_log = "man_power_38";
                    this.data_syslog.detail_log =
                        this.addman_power_38.mp_id_position +
                            ":" +
                            this.addman_power_38.mp_college_code;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_power_38QueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "บันทึกข้อมูลผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.man_power_38QueryAll();
                }
                this.addman_power_38dialog = false;
            }
        },
        async man_power_38Edit(id_m) {
            let result = await this.$http.post("man_power_38.php", {
                ApiKey: this.ApiKey,
                id_m: id_m
            });
            this.editman_power_38 = result.data;
            this.editman_power_38dialog = true;
        },
        async editman_power_38Submit() {
            if (this.$refs.editman_power_38form.validate()) {
                this.editman_power_38.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_power_38.update.php", this.editman_power_38);
                if (result.data.status == true) {
                    this.man_power_38 = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "update";
                    this.data_syslog.page_log = "man_power_38";
                    this.data_syslog.table_log = "man_power_38";
                    this.data_syslog.detail_log =
                        this.editman_power_38.mp_id_position +
                            " : " +
                            this.editman_power_38.mp_college_code +
                            " : " +
                            this.editman_power_38.mp_case_vacancy;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_power_38QueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.editman_power_38dialog = false;
            }
        },
        async man_power_38Delete(id_m) {
            let result = await this.$http.post("man_power_38.php", {
                ApiKey: this.ApiKey,
                id_m: id_m
            });
            this.editman_power_38 = result.data;
            this.deleteman_power_38dialog = true;
        },
        async deleteman_power_38Submit() {
            if (this.$refs.deleteman_power_38form.validate()) {
                this.editman_power_38.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_power_38.delete.php", this.editman_power_38);
                if (result.data.status == true) {
                    this.man_power_38 = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "delete";
                    this.data_syslog.page_log = "man_power_38";
                    this.data_syslog.table_log = "man_power_38";
                    this.data_syslog.detail_log =
                        this.editman_power_38.mp_id_position +
                            " : " +
                            this.editman_power_38.mp_college_code +
                            " : " +
                            this.editman_power_38.mp_case_vacancy;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_power_38QueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.deleteman_power_38dialog = false;
            }
        },
        async ClearMan_powerSubmit() {
            if (this.$refs.ClearMan_powerform.validate()) {
                this.editman_power_38.ApiKey = this.ApiKey;
                let result = await this.$http.post("man_power_38.clear.php", this.editman_power_38);
                if (result.data.status == true) {
                    this.man_power_38 = result.data;
                    Swal.fire({
                        icon: "success",
                        title: "ดำเนินการข้อมูลเรียบร้อย",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
                    this.data_syslog.ApiKey = this.ApiKey;
                    this.data_syslog.user_account = userSession.user_name;
                    this.data_syslog.event_log = "Clear";
                    this.data_syslog.page_log = "man_power_38";
                    this.data_syslog.table_log = "man_power_38";
                    this.data_syslog.detail_log =
                        this.editman_power_38.mp_id_position +
                            " : " +
                            this.editman_power_38.mp_college_code +
                            " : " +
                            this.editman_power_38.mp_case_vacancy;
                    this.data_syslog.date_times = this.date_today_log;
                    await this.$http.post("data_syslog.insert.php", this.data_syslog);
                    this.man_power_38QueryAll();
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "ดำเนินการผิดพลาด",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.ClearMan_powerdialog = false;
            }
        }
    },
    computed: {
        pages() {
            if (this.pagination.rowsPerPage == null ||
                this.pagination.totalItems == null)
                return 0;
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
        },
        date_today_log() {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            let yyyy = today.getFullYear() + 543;
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            today = dd + "/" + mm + "/" + yyyy + "/" + time;
            return today;
        }
    },
    components: { Movement_38menuVue }
};
</script>
